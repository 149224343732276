import './App.css';
import CourierService from './components/Main';

function App() {
  return (
   <div>
    <CourierService/>
   </div>
  );
}

export default App;
