import React from "react";
import fedex from "../Assets/fedex.svg";
import bluedart from "../Assets/bluedart.svg";
import delivery from "../Assets/delivery.png";
import dhl from "../Assets/dhl.svg";

function GlobeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20" />
      <path d="M2 12h20" />
    </svg>
  );
}

const icons = {
  Briefcase: (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 20V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
      <rect width="20" height="14" x="2" y="6" rx="2" />
    </svg>
  ),
  Clock: (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <polyline points="12 6 12 12 16 14" />
    </svg>
  ),

  Globe: (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20" />
      <path d="M2 12h20" />
    </svg>
  ),
  Instagram: (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-phone"
      viewBox="0 0 16 16"
    >
      <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
      <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
    </svg>
  ),
  Linkedin: (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-whatsapp"
      viewBox="0 0 16 16"
    >
      <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
    </svg>
  ),
  Rocket: (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z" />
      <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z" />
      <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0" />
      <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5" />
    </svg>
  ),

  Truck: (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14 18V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v11a1 1 0 0 0 1 1h2" />
      <path d="M15 18H9" />
      <path d="M19 18h2a1 1 0 0 0 1-1v-3.65a1 1 0 0 0-.22-.624l-3.48-4.35A1 1 0 0 0 17.52 8H14" />
      <circle cx="17" cy="18" r="2" />
      <circle cx="7" cy="18" r="2" />
    </svg>
  ),
  Twitter: (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" />
    </svg>
  ),
  Warehouse: (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 8.35V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8.35A2 2 0 0 1 3.26 6.5l8-3.2a2 2 0 0 1 1.48 0l8 3.2A2 2 0 0 1 22 8.35Z" />
      <path d="M6 18h12" />
      <path d="M6 14h12" />
      <rect width="12" height="12" x="6" y="10" />
    </svg>
  ),
};

const Button = ({ children, className, ...props }) => (
  <button
    className={`px-4 py-2 text-sm md:px-6 md:py-3 md:text-base ${className}`}
    {...props}
  >
    {children}
  </button>
);

const Card = ({ children, className, ...props }) => (
  <div
    className={`bg-primary text-primary-foreground p-6 rounded-lg shadow ${className}`}
    {...props}
  >
    {children}
  </div>
);

const CourierService = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-primary text-primary-foreground shadow">
        <div className="container mx-auto px-4 py-4 flex items-center justify-between md:py-6">
          <div className="flex items-center space-x-4">
            <div className="flex items-center ">
              <div className="bg-primary rounded-full p-3 flex items-center justify-center">
                <GlobeIcon className="  text-primary-foreground" />
              </div>
              <h1 className="text-2xl font-bold">Carissa International</h1>
            </div>{" "}
          </div>
          {/* <div className="space-x-2 md:space-x-4">
            <Button className="text-primary-foreground hover:bg-primary-foreground hover:text-primary">
              Login
            </Button>
            <Button className="bg-primary-foreground text-primary hover:bg-primary-foreground/80">
              Sign Up
            </Button>
          </div> */}
        </div>
      </header>

      <main className="container mx-auto px-4 py-12 md:px-6 md:py-22">
        <section className="bg-white shadow-lg rounded-xl p-8 mb-12 md:grid md:grid-cols-2 md:gap-12">
          <div className="md:pl-10 flex flex-col justify-center space-y-6">
            <h1 className="text-4xl md:text-5xl font-extrabold text-gray-800 leading ">
              Reliable courier and cargo services for your business
            </h1>
            <p className="text-lg text-gray-600 font-medium">
              Experience fast, secure, and affordable delivery with our
              top-rated courier service. Get your packages delivered on time,
              every time.
            </p>
            <div>
              <a
                href="tel:+9654078121"
                className="text-blue-600 hover:text-blue-700 transition duration-300"
              >
                <button className="bg-black text-xl px-4 py-2 rounded-md text-white hover:bg-gray-800 transition">
                  Call us
                </button>
              </a>
            </div>
          </div>

          <div className="mt-10 md:mt-0 flex flex-col justify-center space-y-6">
            <h2 className="text-3xl font-bold text-gray-800">
              Contact Information
            </h2>
            <div className="text-gray-700 space-y-3">
              <p className="font-serif text-xl">
                <span className=" font-black ">Carissa International</span>
                <p className=" text-sm">
                  A logistics service provider company.
                </p>
              </p>
              <p>
                <a
                  href="https://maps.app.goo.gl/AZpTaTod9JeCxAhG6"
                  target="_blank"
                  className="  hover:text-red-700 transition duration-300"
                >
                  <strong>Address:</strong> Building No. 2777, Beadon Pura
                  <br />
                  Karol Bagh, New Delhi - 110005
                </a>
              </p>
              <p>
                <a
                  href="tel:+9654078121"
                  className="  hover:text-red-700 transition duration-300"
                >
                  <strong>Phone:</strong> (+91) 9654078121
                </a>
                <br />
                <a
                  href="tel:+8076362895"
                  className="  hover:text-red-700 transition duration-300"
                >
                  <strong>Phone:</strong> (+91) 8076362895
                </a>
              </p>
              <a
                href="mailto:carissa.international@gmail.com"
                className="  hover:text-red-700 transition duration-300"
              >
                <p>
                  <strong>Email:</strong> carissa.international@gmail.com
                </p>
              </a>
            </div>
            <div className="space-y-3">
              <h3 className="text-xl font-bold text-gray-800">Follow Us</h3>
              <div className="flex space-x-5">
                {["Linkedin"].map((social) => {
                  const Icon = icons[social];
                  return (
                    <a
                      key={social}
                      href="https://wa.me/9654078121?text=Hello%20I%20need%20help"
                      className={`text-${social.toLowerCase()}-600 hover:text-${social.toLowerCase()}-700 transition duration-300`}
                    >
                      <Icon className="w-20 h-20" />{" "}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </section>

        <section className=" bg-white shadow rounded-lg p-6 mb-8 md:mb-12">
          <h2 className="text-2xl font-bold mb-4 md:text-3xl">
            Our Courier Services
          </h2>
          <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {[
              {
                icon: "Truck",
                title: "Door to Door Service",
                description:
                  "We provide reliable door-to-door delivery services for your packages.",
              },
              {
                icon: "Rocket",
                title: "Express Delivery",
                description:
                  "Get your packages delivered fast and securely with our express services.",
              },
              {
                icon: "Globe",
                title: "International Shipping",
                description:
                  "Reliable international shipping services to deliver your goods worldwide.",
              },
              {
                icon: "Clock",
                title: "Same Day Delivery",
                description:
                  "Get your packages delivered the same day, no matter the urgency.",
              },
              {
                icon: "Briefcase",
                title: "Freight Forwarding",
                description:
                  "Efficient freight forwarding services for your business needs.",
              },
              {
                icon: "Warehouse",
                title: "Warehousing",
                description:
                  "Secure and reliable warehousing solutions for your inventory.",
              },
            ].map((service, index) => {
              const Icon = icons[service.icon];
              return (
                <Card
                  className={` bg-black/85 text-pretty text-white`}
                  key={index}
                >
                  <Icon className="h-10 w-10 mb-4 text-primary-foreground md:h-12 md:w-12" />
                  <h3 className="text-lg font-bold mb-2 md:text-xl">
                    {service.title}
                  </h3>
                  <p className="text-primary-foreground/80 md:text-base">
                    {service.description}
                  </p>
                </Card>
              );
            })}
          </div>
        </section>

        <section className=" shadow rounded-lg p-6 mb-8 md:mb-12">
          <h2 className="text-2xl font-bold mb-4 md:text-3xl">
            Our Trusted Partners
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-6">
            {[
              { name: "DHL", image: dhl },
              { name: "Blue Dart", image: bluedart },
              { name: "FedEx", image: fedex },
              { name: "Delivery", image: delivery },
            ].map((partner) => (
              <Card
                key={partner.name}
                className="flex items-center bg-black/95 justify-center h-full"
              >
                <img
                  src={partner.image}
                  alt={partner.name}
                  className="w-24 h-auto md:w-32"
                />
              </Card>
            ))}
          </div>
        </section>
      </main>

      <footer className="bg-primary text-primary-foreground py-6 md:py-8">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-4 gap-6">
          <div>
            <h3 className="text-lg font-bold mb-4 md:text-xl">
              About Courier Service
            </h3>
            <p className="text-primary-foreground/80 md:text-base">
              We are a leading professional courier service provider with years
              of experience in the industry. Our mission is to provide fast,
              reliable, and affordable delivery services to our customers.
            </p>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4 md:text-xl">Quick Links</h3>
            <ul className="space-y-2 md:text-base">
              {["Home", "About"].map((item) => (
                <li key={item}>
                  <a
                    href="#"
                    className="text-primary-foreground/80 hover:text-primary-foreground"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4 md:text-xl">Our Services</h3>
            <ul className="space-y-2 md:text-base">
              {[
                "Door to Door Service",
                "International Shipping",
                "Air Freight",
                "Road Transport",
                "Rail Freight",
              ].map((service) => (
                <li key={service}>
                  <a
                    href="#"
                    className="text-primary-foreground/80 hover:text-primary-foreground"
                  >
                    {service}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4 md:text-xl">Get in Touch</h3>
            <p className="text-primary-foreground/80 md:text-base">
              <strong>Address:</strong> Building No. 2777, Beadon Pura Karol
              Bagh, New Delhi - 110005
            </p>
            <p className="text-primary-foreground/80 md:text-base">
              <strong>Phone:</strong>9654078121
            </p>
            <p className="text-primary-foreground/80 md:text-base">
              <strong>Email:</strong> carissa.international@gmail.com
            </p>
          </div>
        </div>
        <div className="container mx-auto px-4 mt-6 md:mt-8 text-center md:text-base">
          <p className="text-primary-foreground/80">
            &copy; 2023 Courier Service. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default CourierService;
<div className="mb-8 md:mb-0">
  <h2 className="text-2xl font-bold mb-4 md:text-3xl">Get in Touch</h2>
  <p className="text-gray-700 mb-4 md:text-base">
    Fill out the form below and we'll get back to you as soon as possible.
  </p>
  <form className="space-y-4">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="space-y-2">
        <label
          htmlFor="first-name"
          className="block text-sm font-medium text-gray-700"
        >
          First Name
        </label>
        <input
          id="first-name"
          placeholder="Enter your first name"
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
        />
      </div>
      <div className="space-y-2">
        <label
          htmlFor="last-name"
          className="block text-sm font-medium text-gray-700"
        >
          Last Name
        </label>
        <input
          id="last-name"
          placeholder="Enter your last name"
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
        />
      </div>
    </div>
    <div className="space-y-2">
      <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700"
      >
        Email
      </label>
      <input
        id="email"
        type="email"
        placeholder="Enter your email"
        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
      />
    </div>
    <div className="space-y-2">
      <label
        htmlFor="message"
        className="block text-sm font-medium text-gray-700"
      >
        Message
      </label>
      <textarea
        id="message"
        placeholder="Enter your message"
        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary min-h-[100px]"
      ></textarea>
    </div>
    <Button className="w-full bg-primary text-primary-foreground hover:bg-primary/80">
      Send Message
    </Button>
  </form>
</div>;
